import React from 'react';
import { getPath } from '@helpers/navigation';
import { LocaleName } from '@stores/locale';
import { get } from 'lodash';
import {
  StarFilled,
  BarChartOutlined,
  CarOutlined,
  InfoCircleFilled,
  BookOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

interface RouteItem {
  name: RouteName;
  title: Record<LocaleName, string>;
  path: string;
  getPath?: (params: Record<string, any>) => string;
  menuSlotes?: MenuSlot[];
  hasMobileVersion?: boolean;
  icon?: JSX.Element;
  addonIcon?: JSX.Element;
  parentRoute?: RouteItem;
  getParentPath?: (params?: any) => string;
}
type RoutesMap = Record<RouteName, RouteItem>;

export type MenuSlot = 'main' | 'side' | 'mobile';

export type RouteName =
  | 'root'
  | 'login'
  | 'buses'
  | 'stats'
  | 'bus'
  | 'busRoute'
  | 'busService'
  | 'news'
  | 'faq';

const routes: RoutesMap = {
  root: {
    title: { ru_RU: 'Домашнаяя страница', en_US: 'Homepage' },
    name: 'root',
    path: '/',
  },
  login: {
    name: 'login',
    title: { ru_RU: 'Войти', en_US: 'Login' },
    path: '/login',
    hasMobileVersion: true,
  },
  buses: {
    name: 'buses',
    title: { ru_RU: 'Автобусы', en_US: 'Buses' },
    path: '/buses',
    menuSlotes: ['main', 'mobile'],
    icon: <CarOutlined />,
    hasMobileVersion: true,
  },
  bus: {
    name: 'bus',
    title: { ru_RU: 'Автобус', en_US: 'Bus' },
    path: '/bus/:busId',
    getPath(params: { busId?: string }) {
      return this.path.replace(':busId', params.busId || 'invalidParam');
    },
    getParentPath() {
      return getPath('buses');
    },
    parentRoute: get(this, 'buses'),
    hasMobileVersion: true,
  },
  busRoute: {
    name: 'busRoute',
    title: { ru_RU: 'Карта маршрута', en_US: 'Bus route' },
    path: '/bus/:busId/gps',
    getPath(params: { busId?: string }) {
      return this.path.replace(':busId', params.busId || 'invalidParam');
    },
  },
  busService: {
    name: 'busService',
    title: {
      ru_RU: 'Камеры салона',
      en_US: 'Service cameras',
    },
    path: '/bus/:busId/service',
    getPath(params: { busId?: string }) {
      return this.path.replace(':busId', params.busId || 'invalidParam');
    },
  },
  stats: {
    name: 'stats',
    title: { ru_RU: 'Статистика', en_US: 'Stats' },
    path: '/stats',
    menuSlotes: ['main', 'mobile'],
    icon: <BarChartOutlined />,
    addonIcon: <StarFilled />,
    hasMobileVersion: true,
  },
  news: {
    name: 'news',
    title: { ru_RU: 'Новости', en_US: 'News' },
    path: '/news',
    addonIcon: <InfoCircleFilled />,
    icon: <BookOutlined />,
    menuSlotes: ['main', 'mobile'],
    hasMobileVersion: true,
  },
  faq: {
    name: 'faq',
    title: { ru_RU: 'Вопрос/Ответ', en_US: 'FAQ' },
    path: '/faq',
    icon: <QuestionCircleOutlined />,
    menuSlotes: ['main', 'mobile'],
    hasMobileVersion: true,
  },
};

export default routes;
