import api from '@constants/endpoints';
import request from '@helpers/request';
import IGpsPoint, { Coordinate } from '@interfaces/IGpsPoint';
import IRouteStop from '@interfaces/IRouteStops';
import { getItem, item } from '@stores/bus';
import createPreloadersStore from '@stores/preloaders';
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { get, has, isEqual, isNumber, map, mapValues, sortBy } from 'lodash';
import update from 'immutability-helper';
import { createDebounce } from '@helpers/effector-debounce';
import $busCalculator from '@stores/busCalculator';
import { IBusGroup } from '@interfaces/IBus';

type CostMap = Record<string, number | undefined>;

export const resetRoute = createEvent('reset soute store');

export const SPLIT_LETTER = ':';

export const generateRoute = createEffect({
  name: 'generateRoute',

  async handler({
    groupId,
    lapId,
    busId,
  }: {
    groupId: string;
    lapId: string;
    busId: string;
    preloaderId: string;
  }) {
    const routeResponse = await request(api.route.generateRoute, {
      urlParams: { groupId },
      params: {
        lapId: lapId,
        workplaceId: busId,
      },
    });

    const stopsResponse = await request(api.route.setStops, {
      data: {
        groupId,
        lapId,
        workplaceId: busId,
      },
    });

    return {
      route: routeResponse.data,
      stops: stopsResponse.data,
    };
  },
});

export const createRouteFromGpx = createEffect({
  name: 'createRouteFromGpx',
  async handler(data: { formData: FormData; busId: string }) {
    const response = await request(api.route.uploadGpx, {
      data: data.formData,
    });
    const newRouteId = get(response, ['data', 'id']);
    await request(api.route.addRouteForBus, {
      data: {
        groupId: newRouteId,
        workplaceId: data.busId,
      },
    });
    return response.data;
  },
});

export const updateRouteFromGpx = createEffect({
  name: 'updateRouteFromGpx',
  async handler({
    formData,
    groupId,
  }: {
    formData: FormData;
    groupId: string;
    preloaderId: string;
  }) {
    const response = await request(api.route.uploadGpxAndUpdate, {
      data: formData,
      params: { groupId },
    });
    return response.data as IBusGroup;
  },
});

export const addGroupForBus = createEffect({
  name: 'addGroupForBus',

  async handler(data: { groupId: string; workplaceId: string }) {
    const response = await request(api.route.addRouteForBus, {
      data,
    });
    return response.data;
  },
});

export const removeGroupForBus = createEffect({
  name: 'removeGroupForBus',

  async handler(data: { groupId: string; workplaceId: string }) {
    const response = await request(api.route.deleteRouteForBus, {
      params: data,
    });
    return response.data;
  },
});

export const getStops = createEffect({
  name: 'getStops',

  async handler({ groupId }: { groupId: string }) {
    const response = await request(api.route.getStops, {
      urlParams: { groupId },
    });
    return response.data;
  },
});

export const getGroup = createEffect({
  name: 'getGroup',

  async handler({ groupId }: { groupId: string }) {
    const response = await request(api.route.getGroup, {
      urlParams: { groupId },
    });
    return response.data as IBusGroup;
  },
});

export const updateRouteStop = createEffect({
  name: 'updateRouteStop',

  async handler({ data }: { data: IRouteStop | null; preloaderId?: string }) {
    const response = await request(api.route.updateStop, {
      urlParams: { stopId: get(data, 'id') },
      data,
    });
    return response.data;
  },
});

export const deleteRouteStop = createEffect({
  name: 'deleteRouteStop',

  async handler({
    stopId,
  }: {
    stopId: IRouteStop['id'];
    preloaderId?: string;
  }) {
    const response = await request(api.route.deleteStop, {
      urlParams: { stopId },
    });
    return response.data;
  },
});

export const getCost = createEffect({
  name: 'getCost',

  async handler({ groupId }: { groupId: string }) {
    const response = await request(api.route.getCost, {
      urlParams: { groupId },
    });
    return response.data;
  },
});

export const updateCost = createEffect({
  name: 'updateCost',

  async handler(payload: {
    groupId: string;
    items: {
      cost: number | undefined;
      from_stop_id: string;
      to_stop_id: string;
    }[];
  }) {
    const response = await request(api.route.updateCost, {
      data: payload,
    });
    return response.data;
  },
});

sample({
  source: item,
  clock: getItem.done,
  target: getCost,
  filter: (bus) => !!get(bus, ['group', 'id']),
  fn: (bus) => ({ groupId: get(bus, ['group', 'id']) }),
});

export const getStopsForCurrentBus = createEvent('Get Stops For Current Bus');

sample({
  source: item,
  target: getStops,
  clock: getStopsForCurrentBus,
  filter: (bus) => has(bus, ['group', 'id']),
  fn: (bus) => ({ groupId: get(bus, ['group', 'id']) }),
});

export const $track = createStore<Coordinate[]>([])
  .on([getItem.done, createRouteFromGpx.done], (state, { result }) => {
    const points = get(result, ['group', 'track'], []) || [];
    return points.map((g: IGpsPoint) => [g.latitude, g.longitude]);
  })
  .on(generateRoute.done, (state, { result }) =>
    get(result, ['route', 'track'], []).map((g: IGpsPoint) => [
      g.latitude,
      g.longitude,
    ]),
  )
  .on(getGroup.done, (state, { result }) =>
    get(result, ['track'], []).map((g: IGpsPoint) => [g.latitude, g.longitude]),
  )
  .reset(resetRoute);

export const setDirection = createEffect({
  name: 'setDirection',

  async handler(data: {
    groupId: string;
    firstRouteStopId?: string;
    lastRouteStopId?: string;
  }) {
    const response = await request(api.route.setDirection, {
      data,
    });
    return response.data;
  },
});

export const $stops = createStore<IRouteStop[]>([])
  .on(generateRoute.done, (state, { result }) =>
    sortBy(get(result, ['stops'], []), 'orderKey'),
  )
  .on(createRouteFromGpx.doneData, (state, { routeStops }) => routeStops)
  .on([getStops.done, setDirection.done], (_, { result }) =>
    sortBy(result, 'orderKey'),
  )
  .on(updateRouteStop.done, (state, { result }) => {
    const index = state.findIndex((s) => s.id === result.id);
    if (index < 0) return state;
    return update(state, {
      $splice: [[index, 1, result]],
    });
  })
  .on(deleteRouteStop.done, (state, { params }) => {
    const index = state.findIndex((s) => s.id === params.stopId);
    if (index < 0) return state;
    return update(state, {
      $splice: [[index, 1]],
    });
  });

export const updateRouteStopAttached = attach({
  source: $stops,
  effect: updateRouteStop,
  mapParams: (params: { id: string; name?: string }, stops) => {
    const selectedStop = stops.find((s) => s.id === params.id);
    if (!selectedStop)
      return {
        data: null,
      };
    return {
      data: { ...selectedStop, name: params.name || '' },
      preloaderId: selectedStop.id,
    };
  },
});

export const setDirectionAttached = attach({
  source: $stops,
  effect: setDirection,
  mapParams: (
    params: {
      groupId: string;
      firstRouteStopId?: string;
      lastRouteStopId?: string;
    },
    stops,
  ) => {
    const firstRouteStopId = get(
      stops.find((s) => s.routeDirection === 'FORWARD'),
      'id',
    );
    const lastRouteStopId = get(
      stops.find((s) => s.routeDirection === 'REVERSE'),
      'id',
    );
    return { firstRouteStopId, lastRouteStopId, ...params };
  },
});

export const setEditingHalt = createEvent<IRouteStop['id'] | null>(
  'set editing halt',
);
export const $editingHaltId = createStore<IRouteStop['id'] | null>(null)
  .on(setEditingHalt, (_, id) => id)
  .reset(updateRouteStop.done);

export type TabName = 'stops' | 'price' | 'actions' | 'routes';
export const setSelectedTab = createEvent<TabName>('set editing halt');
export const $selectedTab = createStore<TabName>('stops').on(
  setSelectedTab,
  (_, tabName) => tabName,
);

const getCostHandler = (
  state: Record<string, any>,
  { result }: { result: any },
) =>
  result.reduce(
    (
      acc: CostMap,
      item: {
        from_stop_id: string;
        to_stop_id: string;
        cost: number;
      },
    ) => {
      acc[`${item.from_stop_id}${SPLIT_LETTER}${item.to_stop_id}`] = item.cost;
      return acc;
    },
    {},
  );

const $serverCostMap = createStore<CostMap>({}).on(
  getCost.done,
  getCostHandler,
);

export const setCostMap = createEvent<CostMap>('set cost map');
export const $localCostMap = createStore<CostMap>({})
  .on(setCostMap, (state, payload) => ({ ...state, ...payload }))
  .on(getCost.done, getCostHandler);

sample({
  source: [$localCostMap, $stops, $busCalculator],
  target: [$localCostMap],
  clock: [getCost.done, getStops.done, generateRoute.done],
  fn: ([actualData, stops, calc], clock) => {
    const costMap: Record<string, number | undefined> = {};
    const fare = get(calc, 'fare');
    stops.forEach((s1) => {
      stops.forEach((s2) => {
        const key = `${s1.id}${SPLIT_LETTER}${s2.id}`;
        costMap[key] = s1.id === s2.id ? 0 : fare || undefined;
      });
    });
    return mapValues({ ...costMap, ...actualData }, (v) =>
      isNumber(v) ? v : fare,
    );
  },
});

const debouncedCostMapEvent = createDebounce($localCostMap, 500);

sample({
  source: [$localCostMap, $serverCostMap, item],
  target: updateCost,
  clock: [debouncedCostMapEvent],
  filter: ([l, s, b]) => {
    const isCalculationType =
      get(b, ['group', 'type_calculation'], 'FIXED') === 'CALCULATION';
    const isUpdated = !isEqual(l, s);
    return isUpdated && isCalculationType;
    // return isUpdated;
  },
  fn: ([localData, serverData, bus]) => {
    const groupId: string = get(bus, ['group', 'id'], '');
    const items = map(localData, (cost, key) => {
      const [from_stop_id, to_stop_id] = key.split(SPLIT_LETTER);
      return { cost, from_stop_id, to_stop_id };
    });
    const payload = {
      groupId,
      items,
    };
    return payload;
  },
});

export const focusStops = createEvent<Array<string | null>>('focus stops');

export const $focusedStopIds = createStore<Array<string | null>>([]).on(
  focusStops,
  (_, payload) => payload,
);

export const $preloaders = createPreloadersStore([
  generateRoute,
  getStops,
  updateRouteStop,
  deleteRouteStop,
  setDirection,
  createRouteFromGpx,
  updateRouteFromGpx,
]);
