import React, { FunctionComponent, useState } from 'react';
import css from './RoutePriceTable.module.less';
// import componentContent from './RoutePriceTable.content.json';
// import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import {
  $focusedStopIds,
  $localCostMap,
  $stops,
  focusStops,
  setCostMap,
  SPLIT_LETTER,
} from '@stores/route';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import classNames from 'classnames';
import useAdmin from '@hooks/useAdmin';

interface RoutePriceTableProps {}

const RoutePriceTable: FunctionComponent<RoutePriceTableProps> = () => {
  // const content = useTranslate(componentContent);
  const stops = useStore($stops);
  const [coordinate, setCoordinate] = useState<Array<string | null>>([
    null,
    null,
  ]);
  const costMap = useStore($localCostMap);
  const isAdmin = useAdmin();

  const focusedCoordinate = useStore($focusedStopIds);

  const selectedX = focusedCoordinate[0] || coordinate[0];
  const selectedY = focusedCoordinate[1] || coordinate[1];

  return (
    <ScrollSync>
      <div className={css.container}>
        <div className={css.angleCell}></div>
        <ScrollSyncPane>
          <div className={css.fixedColumn}>
            {stops.map((s) => (
              <div
                className={classNames(css.cell, {
                  [css.hovered]: s.id === selectedX,
                })}
                key={s.id}
              >
                <div className={css.ellipsis} title={s.name}>
                  {s.name}
                </div>
              </div>
            ))}
          </div>
        </ScrollSyncPane>
        <div className={css.body}>
          <div className={css.horizontalScrollWrapper}>
            <div
              className={css.innerScrollContainer}
              style={{ width: 160 * stops.length }}
            >
              <div className={css.fixedRow}>
                {stops.map((s) => (
                  <div
                    className={classNames(css.cell, {
                      [css.hovered]: s.id === selectedY,
                    })}
                    key={s.id}
                  >
                    <div className={css.ellipsis} title={s.name}>
                      {s.name}
                    </div>
                  </div>
                ))}
              </div>
              <ScrollSyncPane>
                <div
                  className={css.verticalScrollingContainer}
                  onMouseLeave={() => setCoordinate([null, null])}
                >
                  {stops.map((x) => (
                    <div className={css.row} key={x.id}>
                      {stops.map((y) => (
                        <div
                          className={classNames(css.cell, css.editable, {
                            [css.hovered]:
                              selectedX === x.id || selectedY === y.id,
                          })}
                          key={y.id}
                          onMouseEnter={() => setCoordinate([x.id, y.id])}
                        >
                          <input
                            disabled={!isAdmin}
                            className={css.input}
                            value={costMap[[x.id, y.id].join(SPLIT_LETTER)]}
                            onChange={(e) =>
                              setCostMap({
                                [[x.id, y.id].join(SPLIT_LETTER)]:
                                  Number(e.target.value) || 0,
                                [[y.id, x.id].join(SPLIT_LETTER)]:
                                  Number(e.target.value) || 0,
                              })
                            }
                            onFocus={() => focusStops([x.id, y.id])}
                            onBlur={() => focusStops([null, null])}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </ScrollSyncPane>
            </div>
          </div>
        </div>
        <ScrollSyncPane>
          <div className={css.rightScroll}>
            <div style={{ height: stops.length * 36 }} />
          </div>
        </ScrollSyncPane>
      </div>
    </ScrollSync>
  );
};

export default RoutePriceTable;
