import React, { FunctionComponent, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Divider, Card, Spin, Tooltip, Typography } from 'antd';
import Daychart from '@components/Daychart';
import css from './Bus.module.less';
import { useStore } from 'effector-react';
import bus, { getItem, reset as resetBus, setTab } from '@stores/bus';
import {
  resetPeriods,
  preloaders as $segmentsPreloaders,
} from '@stores/periods';
import FragmentList from '@components/FragmentList';
import BusHeader from '@components/BusHeader';
import BusListSelector from '@components/BusListSelector';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '@config/format';
import SelectedSegmentTabs from '@components/SelectedSegmentTabs';
import GpsTab from '@components/GpsTab';
import { get } from 'lodash';
import ServiceTab from '@components/ServiceTab';
import useQuery from '@hooks/useQuery';
import { reset as resetDate, initDate } from '@stores/date';
import $auth from '@stores/auth';
import moment from 'moment';
import IBus from '@interfaces/IBus';
import ICamera from '@interfaces/ICamera';
import GetBusReportButton from '@components/GetBusReportButton';
import RecalcButton from '@components/RecalcButton';
import DateSelector from '@components/DateSelector';
import MergeSegmentsButton from '@components/MergeSegmentsButton';
import MobileDayInfo from '@components/MobileDayInfo';
import MobileBottomWidget from '@components/MobileBottomWidget';
import BusTabs from '@components/BusTabs';
import Timelines from '@components/Timelines';
import $video, { setSegment } from '@stores/video';
import { resetRouteTitle, setRouteTitle } from '@stores/app';
import useMedia from '@hooks/useMedia';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { busGroups } from '@stores/buses';
import { setBus } from '@helpers/navigation';
import useBusesNavigation from '@hooks/useBusesNavigation';
import componentContent from './Bus.content.json';
import useTranslate from '@hooks/useTranslate';
import useSegments from '@hooks/useSegments';

interface BusProps {}

const Bus: FunctionComponent<BusProps> = () => {
  const routeMatch = useRouteMatch<{ busId: string }>();
  const {
    params: { busId },
  } = routeMatch;
  const content = useTranslate(componentContent);

  const {
    item,
    preloaders: busPreloaders,
    serviceCameras,
    activeTab,
  } = useStore(bus);

  const allSegments = useSegments();

  const periodsPreloaders = useStore($segmentsPreloaders);

  const { user } = useStore($auth);
  const isAdmin = user ? user.role === 'ADMIN' : false;

  const currentClientId = isAdmin
    ? get(item, ['clients', 0, 'id'])
    : get(user, ['id']);
  const allGroups = useStore(busGroups);

  const [previousBusId, nextBusId] = useBusesNavigation({
    currentClientId,
    item,
    allGroups,
  });

  const { selected } = useStore($video);
  const selectedSegment = selected ? selected?.segment : null;
  const queryParams = useQuery();
  const queryDate = queryParams.get('date');

  useEffect(() => {
    setSegment(undefined);
    getItem({
      urlParams: { busId },
      params: {
        date: queryDate
          ? moment(queryDate, DATE_FORMAT).format(SERVER_DATE_FORMAT)
          : moment().format(SERVER_DATE_FORMAT),
      },
      callback: (data: IBus) => {
        const serviceCameras: ICamera[] = [];
        const pureCameras: ICamera[] = [];
        data.cameras.forEach((c) =>
          c.live ? serviceCameras.push(c) : pureCameras.push(c),
        );
        if (pureCameras.length === 0 && serviceCameras.length !== 0)
          setTab('service');
        setRouteTitle(get(data, 'name', ''));
      },
    });
    return () => {
      resetRouteTitle();
      resetPeriods();
      resetBus();
      resetDate();
      setSegment(undefined);
    };

    // eslint-disable-next-line
  }, [busId]);

  useEffect(() => {
    if (queryDate && item.id) {
      initDate(moment(queryDate, DATE_FORMAT));
    } else if (item.id) {
      initDate(moment());
    }
    // eslint-disable-next-line
  }, [item.id]);

  useEffect(() => {
    setSegment(undefined);
  }, [activeTab]);

  const { isTabletOrMobile, withFields } = useMedia();

  return (
    <>
      {isTabletOrMobile ? (
        <MobileDayInfo />
      ) : (
        <Card>
          <div className={css.title}>
            {withFields ? <BusListSelector /> : null}
            <h1 className={css.busName}>
              {previousBusId ? (
                <Tooltip title={content.toPrev}>
                  <LeftOutlined onClick={() => setBus(previousBusId)} />
                </Tooltip>
              ) : null}
              <div className={css.busNameWrapper}>
                <Spin spinning={!!busPreloaders.getItem}>{item?.name}</Spin>
                {isAdmin && item.group_device_id ? (
                  <div
                    className={css.deviceId}
                    onClick={(e) => e.preventDefault()}
                  >
                    {content.deviceId}:{' '}
                    <Typography.Text copyable>
                      {item.group_device_id}
                    </Typography.Text>
                  </div>
                ) : null}
              </div>
              {nextBusId ? (
                <Tooltip title={content.toNext}>
                  <RightOutlined onClick={() => setBus(nextBusId)} />
                </Tooltip>
              ) : null}
            </h1>

            <DateSelector />
            <GetBusReportButton />
            <MergeSegmentsButton />
            {isAdmin ? <RecalcButton /> : null}
          </div>
          {serviceCameras.length || item.gps_trackable ? (
            <BusTabs />
          ) : (
            <Divider />
          )}
          {activeTab === 'count' ? (
            <>
              <BusHeader />
              <Divider />
              <Spin
                spinning={
                  !!periodsPreloaders.getEvents || !!periodsPreloaders.getList
                }
                className={css.chartContainer}
              >
                <Daychart />
              </Spin>
              <Spin spinning={!!periodsPreloaders.getList}>
                <Timelines />
              </Spin>
            </>
          ) : null}
          {activeTab === 'service' ? <ServiceTab /> : null}
          {activeTab === 'gps' ? <GpsTab /> : null}
        </Card>
      )}
      {isTabletOrMobile ? (
        <MobileBottomWidget>
          <div></div>
        </MobileBottomWidget>
      ) : (
        <div>
          {activeTab === 'count' && allSegments.length ? (
            <div className={css.fragments}>
              <div className={css.playlist}>
                <Spin spinning={!!periodsPreloaders.getList}>
                  <FragmentList
                    editable={isAdmin}
                    periods={allSegments}
                    key="count"
                  />
                </Spin>
              </div>
              <div>
                {selectedSegment ? (
                  <SelectedSegmentTabs videoClassName={css.video} />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Bus;
